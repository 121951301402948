<template>
     <pageTop></pageTop>
     <router-view v-slot="{ Component }">
      <transition name="fade">
    <component :is="Component" />
    </transition>
      </router-view>
    <pageBot></pageBot>
 
</template>

<script>
import pageTop from '@/components/pageTop'
import pageBot from '@/components/pageBot'
export default {
  name: "App",
  components: {
    pageTop,
    pageBot
  },

};
</script>

<style>
#app {
 overflow : hidden;
}

section {
  padding-top: 110px;
  position: relative;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>