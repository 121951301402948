<template>
  <section class="h-auto justify-items-center">
    <div class="container-costum">
      <h2 class="section-title">Portfolio</h2>
      <div style="height: 30px"></div>
      <!-- Three columns -->
      <div class="px-2">
        <div class="flex flex-wrap -mx-2">
          <div v-for="portfolio in portfolios" :key="portfolio" class="w-full lg:w-1/3 xl:w-1/3 px-2 my-2">
            <div class="portfolio-item rounded-costum overflow-hidden bg-white shadow-md">
              <div class="details">
                <span class="term">{{portfolio.type}}</span>
                <h4 class="title">{{portfolio.title}}</h4>
                 <a href="#" id="show-modal" @click.prevent="showPortfolio(portfolio)" class="work-content">
                   <span class="more-button"><i><fa icon="search-plus" type="fas"></fa></i></span>
                </a>
                <a :href='portfolio.url'><span class="link-button"><i><fa icon="link" type="fas"></fa></i></span></a>
              </div>
              <div class="thumb">
                <img :src="portfolio.thumbnail" alt="Portfolio-title" style="height:200px !important"/>
                <div class="mask"></div>
              </div>
            </div>
            <div v-if="singleView">
            <modal :portfolio="active_portfolio" @close="closePortfolio"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 60px"></div>
  </section>
</template>
<script>
import modal from '@/components/modal.vue'
import { mapGetters,mapActions } from 'vuex'
export default {
    computed : {
      ...mapGetters([
        "active_portfolio",
        "portfolios",
        "singleView"
      ])
    },
    components:{
        modal
    },
    methods:{
      ...mapActions([
        'showPortfolio',
        'closePortfolio'
      ])
    },
};
</script>
<style scoped>
.rounded-costum {
  border-radius: 20px;
}
.portfolio-wrapper [class*="col-"] {
  margin-top: 15px;
  margin-bottom: 15px;
}

.pf-filter-wrapper {
  display: none;
}

.portfolio-item {
  position: relative;
  overflow: hidden;
}
.portfolio-item .thumb {
  overflow: hidden;
}
.portfolio-item .details {
  color: #fff;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.portfolio-item .details h4 {
  color: #fff;
  font-size: 20px;
  margin: 0 0 10px;
  padding: 0 20px;
  opacity: 0;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  -o-transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  position: absolute;
  top: 60px;
}
.portfolio-item .details span.term {
  color: #fff;
  background: #ff4c60;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-size: 14px;
  opacity: 0;
  display: inline-block;
  padding: 3px 10px;
  position: absolute;
  top: 0;
  left: 20px;
  -webkit-transform: translateY(-40px);
  -ms-transform: translateY(-40px);
  transform: translateY(-40px);
  -webkit-transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  -o-transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
}
.portfolio-item .details .more-button {
  color: #fff;
  font-size: 20px;
  display: block;
  background: #ffd15c;
  border-radius: 100%;
  height: 37px;
  line-height: 35px;
  text-align: center;
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 37px;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.portfolio-item .details .link-button {
  color: #fff;
  font-size: 20px;
  display: block;
  background: #ffd15c;
  border-radius: 100%;
  height: 37px;
  line-height: 35px;
  text-align: center;
  position: absolute;
  bottom: 20px;
  left: 70px;
  width: 37px;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.portfolio-item .mask {
  background: #6c6ce5;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.portfolio-item:hover .mask {
  opacity: 0.9;
}
.portfolio-item:hover .details h4,
.portfolio-item:hover .details span {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.portfolio-item:hover .details .more-button {
  opacity: 1;
}
.portfolio-item:hover .details .link-button {
  opacity: 1;
}
</style>