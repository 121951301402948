<template>
  <section class="h-auto justify-items-center">
    <div class="container-costum">
      <h2 class="section-title">Experience</h2>
      <div style="height: 30px"></div>
      <div class="flex flex-wrap mb-4">
    <div class="w-full lg:w-1/2 xl:w-1/2 px-2" style="margin-bottom: 1rem;">
      <div class="timeline edu rounded overflow-hidden bg-white shadow-lg" style="padding: 30px ; overflow: hidden">
        <div v-for="education in educations" :key="education" class="timeline-container">
          <div class="content">
            <span class="time">{{ education.date }}</span>
            <h3 class="title">{{ education.school }}</h3>
            <p class="text-base">{{ education.study }}</p>
            <p class="text-sm">{{ education.address }}</p>
          </div>
        </div>
        <span class="line"></span>
      </div>
      </div>
          <div class="w-full lg:w-1/2 xl:w-1/2 px-2">
      <div class="timeline exp rounded overflow-hidden bg-white shadow-lg" style="padding: 30px 30px 158px 30px; overflow: hidden">
        <div v-for="experience in experiences" :key="experience" class="timeline-container">
          <div class="content">
            <span class="time">{{ experience.date }}</span>
            <h3 class="title">{{ experience.company }}</h3>
            <p class="text-base">{{ experience.job }}</p>
            <p class="text-sm">{{ experience.address }}</p>
          </div>
        </div>
        <span class="line"></span>
      </div>
      </div>
    </div>
    </div>
    <div style="height:60px;"></div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["educations","experiences"]),
  },
};
</script>

<style scoped>

.timeline {
  position: relative;
}

.timeline .timeline-container {
  padding-left: 50px;
  margin-bottom: 50px;
  position: relative;
  background-color: inherit;
  width: 100%;
}
.timeline .timeline-container:last-of-type {
  margin-bottom: 0;
}

.timeline.edu .timeline-container::after {
  content: url(/img/mortarboard.svg);
  background: #fff;
  font-size: 24px;
  position: absolute;
  left: -7px;
  top: 0;
  z-index: 1;
}

.timeline.exp .timeline-container::after {
  content: url(/img/portfolio.svg);
  background: #fff;
  font-size: 24px;
  position: absolute;
  left: -5px;
  top: 0;
  z-index: 1;
}

.timeline .content {
  position: relative;
}

.timeline .content .time {
  color: #8b88b1;
  font-size: 14px;
}

.timeline .content h3 {
  font-size: 20px;
  margin: 10px 0;
}

.timeline .content p {
  margin: 0;
}

.timeline span.line {
  position: absolute;
  width: 1px;
  background-color: #ffd15c;
  top: 30px;
  bottom: 30px;
  left: 34px;
}
</style>