<template>
  <section class="h-auto justify-items-center">
    <div class="container-costum">
      <h2 class="section-title">Skill</h2>
      <div style="height: 30px"></div>
      <div class="rounded overflow-hidden bg-white shadow-lg">
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            What Can I Do ?
          </div>
            <div class="px-6 flex flex-wrap mb-4">
                <div class="w-full lg:w-1/2 xl:w-1/2 my-2" v-for="skill in skills" :key="skill" >
                    <li>{{skill.title}}</li>
                </div>
            </div>
        </div>
        <div class="px-6 flex flex-wrap mb-4">
          <div v-for="skill_p in skills_percentage" :key="skill_p" class="w-1/2 h-12 px-4 my-2">
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">{{skill_p.title}}</h4>
                <span class="float-right">{{skill_p.percentage}}</span>
              </div>
              <div class="progress">
                <div class="progress-bar " v-bind:style="{backgroundColor:skill_p.color,width:skill_p.percentage + '%'}"></div>
              </div>
              <div class="spacer" data-height="20" style="height: 20px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 60px"></div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["personals","skills","skills_percentage"]),
  },
  methods: {
    getPhoto() {
      return "/img/";
    },
  },
};
</script>

<style scoped>
.skill-item .skill-info h4 {
  font-size: 16px;
  font-weight: 500;
}
.skill-item .skill-info span {
  font-size: 14px;
}

/* === Progress Bar === */
.progress {
  height: 7px;
  margin-bottom: 0;
  overflow: hidden;
  background-color: #f1f1f1;
  border-radius: 15px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.progress-bar {
  border-radius: 15px;
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 7px;
  color: #fff;
  text-align: center;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
</style>