import * as mutations from './mutation-types'

export default {
    [mutations.SHOW_PORTFOLIO] (state,portfolio){
        state.singleView = true
        state.active_portfolio = portfolio
    },
    [mutations.CLOSE_PORTFOLIO] (state){
        state.singleView = false
    },
}