<template>
  <section class="home">
    <div class="container">
      <parallax></parallax>
      <div class="intro">
        <!-- avatar image -->
        <img :src="getPhoto() + personals.picture" />
        <!-- info -->
        <h1 class="mb-1 text-white">{{ personals.fullname }}</h1>
        <span class="text-white text-sm  mx-8">I'm a &nbsp;{{ personals.position }}</span>
        <!-- social icons -->
        <ul class="social-icons light list-inline my-6" >
          <li class="list-inline-item" v-for="socialmedia in personals.social_media" :key="socialmedia">
            <a :href='socialmedia.url'><i><fa :icon='socialmedia.icon' type="fab"></fa></i></a>
          </li>
        </ul>
        <!-- buttons -->
        <div class="my-6">
          <button @click="forceFileDownload" class="btn btn-default">
            <span>Download CV</span>
          </button>
        </div>
      </div>
    </div>
    <div style="height: 60px"></div>
  </section>
</template>

<script>
import { mapGetters } from "vuex"
import parallax from '@/components/parallax'
// @ is an alias to /src
export default {
  components :{
    parallax
  },
  computed: {
    ...mapGetters(["personals"]),
  },
  methods:{
    getPhoto(){
      return "/img/"
    },    
    
    forceFileDownload(){
      const url = '/CV.pdf'
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'CV.pdf') //or any other extension
      document.body.appendChild(link)
      link.click()
    },
  },
};
</script>

<style scoped>
section.home {
  background: #353353;
  height: 100vh;
  min-height: 100vh;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}
section.home .intro {
  margin: auto;
  max-width: 540px;
  text-align: center;
  position: relative;
  z-index: 1;
}
section.home .social-icons li a {
  color: #fff;
}
/* === Social Icons === */

.social-icons li:not(:last-child) {
  margin-right: 1.5rem;
}

.social-icons.light li a:hover {
  color: #ffd15c;
}

.list-inline-item {
    display: inline-block;
}

</style>
