<template>
  <section class="h-auto justify-items-center">
    <div class="container-costum">
      <h2 class="section-title wow fadeInUp">About Me</h2>
      <div style="height: 30px"></div>
      <div class="flex flex-wrap mb-4">
        <div class="w-full lg:w-1/5 xl:w-1/5 px-2">
          <div class="text-center text-md-left">
              <img :src="getPhoto() + personals.picture2" alt="FaJAR"/>
          </div>
          <div
            class="spacer d-md-none d-lg-none"
            data-height="30"
            style="height: 30px"
          ></div>
        </div>
        <div class="w-full lg:w-3/4 xl:w-3/4 px-2">
          <div class="rounded overflow-hidden bg-white shadow-lg">
            <div class="px-6 py-4">
              <div class="font-bold text-xl mb-2">
                {{ personals.fullname }} - {{ personals.position }}
              </div>
              <p class="text-gray-700 text-base">
                My name is Fajar Buana People call me Fajar, I'm very
                enthusiastic about programming and design, my favorite
                programming language is laravel i spend a lot of time on code
                and design while chatting on discord
              </p>
            </div>
            <div class="px-6 py-4 flex flex-wrap mb-4">
              <div class="w-full lg:w-1/3 md:w-1/3">
                <span class="font-bold">Address :</span>
                <p class="text-gray-700 text-base">{{ personals.address }}</p>
              </div>
              <div class="w-full lg:w-1/3 md:w-1/3">
                <span class="font-bold">Phone :</span>
                <p class="text-gray-700 text-base">{{ personals.phone }}</p>
              </div>
              <div class="w-full lg:w-1/3 md:w-1/3">
                <span class="font-bold">Email :</span>
                <p class="text-gray-700 text-base">{{ personals.email }}</p>
              </div>
            </div>
            <div class="px-6 pt-4 pb-2">
              <span
                v-for="personal in personals.hobby"
                :key="personal.id"
                class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
                >{{ personal.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
     <div style="height:60px;"></div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["personals"]),
  },
   methods:{
    getPhoto(){
      return "/img/"
    },    
  }
  
};

</script>

<style scoped>

</style>