import * as mutations from './mutation-types'

export default {
    showPortfolio(context,portfolio){
        //manggil mutation (commit)
        context.commit(mutations.SHOW_PORTFOLIO,portfolio)
    },
    closePortfolio(context){
        //manggil mutation (commit)
        context.commit(mutations.CLOSE_PORTFOLIO)
    }
}
