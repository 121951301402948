<template>
  <footer class="footer text-center">
    <div class="container">
      <span class="footer-info">Made With Vue Js & Tailwind &#169; Fajar - Bogor</span>
    </div>
  </footer>
</template>

<script>
export default {
 
}
</script>

<style scoped>
footer.footer {
    background: #353353;
    padding: 40px 0;
}
footer.footer .footer-info {
    color: #9C9AB3;
    font-size: 14px;
}
</style>