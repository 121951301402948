export default {
    personals : state => {
        return state.data.personal
    },
    skills : state => {
        return state.data.skills
    },
    skills_percentage : state => {
        return state.data.skills_percentage
    },
    educations : state => {
        return state.data.educations
    },
    experiences : state => {
        return state.data.experiences
    },
    portfolios : state => {
        return state.data.portfolio
    },
    menus : state => {
        return state.data.menu
    },
    singleView : state => {
        return state.singleView
    },
    active_portfolio : state => {
        return state.active_portfolio
    }
}