<template>
  <div class="pageTop" id="nav">
    <header class="desktop-header fixed-top">
     <nav class="flex justify-between flex-wrap bg-grey-darkest w-full z-10 pin-t">
       <div class="flex items-center flex-no-shrink text-white mr-6">
			<a class="text-white no-underline hover:text-white hover:no-underline" href="#">
				<span class="text-2xl pl-2"><i class="em em-grinning"></i></span>
			</a>
		</div>
		<div class="block lg:hidden">
			<button id="nav-toggle" class="flex items-right px-3 mx-4 py-2 border rounded text-grey border-grey-dark hover:text-white hover:border-white">
				<svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
			</button>
		</div>
        <div class="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block pt-6 lg:pt-0" id="nav-content">
          <div class="text-sm lg:flex-grow nav-link">
            <router-link  v-for="menu in menus" :key="menu" :to="{path: menu.path}" append class="block mt-4 lg:inline-block lg:mt-0 nav-link text-white mr-8">{{menu.title}}</router-link>
          </div>
        </div>
      </nav>
    </header>
  </div>
</template>
<script>

import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["personals", "menus"]),
  },
};
</script>

<!-- Add 'Scoped' attribute to limit CSS to this component only -->
<style scoped>

#nav {
  text-align: center;
}

#nav a {
  font-weight: 700;
  color: #ffffff;
  font-size : 16px;
}

#nav a.router-link-exact-active {
  color: #FFD15C !important
}

.desktop-header {
  background: #353353;
  padding: 20px 0;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}
.desktop-header .nav-link {
    font-size: 16px;
    font-weight: 700;
    padding: 0;
}
@media only screen and (max-width: 1024px) {
  .nav-link {
    text-align: left;
    margin-left: 1rem;
  }
}

</style>